$primary-color: #009FD6;
$primary-color-hover: #0C7BAD;
$secondary-color: #0C7BAD;
$secondary-color-hover: #0C7BAD;
$black: #000000;
$white: #FFFFFF;
$light-grey: #E9E9E9;
$light-grey-bg: #f0f0f0;
$grey-hover: #E1E1E1;
$grey-line: #DEDEDE;
$dark-grey-line: #979797;
$dark-grey: #3c3c3c;
$grey-black: #1E1E1E;

$green: #17AC39;
$green-hover: #00661C; 

$primary-text-color: #3c3c3c;
$primary-text-info-color: #757575;


:root {
  --primary-color: #009FD6;
  --primary-color-hover: #0C7BAD;
  --secondary-color: #0C7BAD;
  --secondary-color-hover: #0C7BAD;
  --black: #000000;
  --white: #FFFFFF;
  --light-grey: #E9E9E9;
  --light-grey-bg: #f0f0f0;
  --grey-hover: #E1E1E1;
  --grey-line: #DEDEDE;
  --dark-grey-line: #979797;
  --dark-grey: #3c3c3c;
  --green: #17AC39;
  --green-hover: #00661C;
  --primary-text-color: #3c3c3c;
  --primary-text-info-color: #757575;

  --primary-font-family: Roboto, "Helvetica Neue", sans-serif;
}