@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  [multiple] {
    background-color: inherit;
    border: none;
    padding: 0;
    line-height: 1rem;
    font-size: 1rem;

    &:focus {
      outline: none;
    }
  }

  p {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  *, :before, :after {
    box-sizing: content-box;
  }

  app-cms-module-wrapper p {
    padding: 0;
  }

  :focus-visible {
    outline: none !important;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Literata:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

@import "colors.scss";
@import "variables.scss";

/* You can add global styles to this file, and also import other style files */
html, body { height: 100%; scroll-behavior: smooth; }
body { margin: 0; font-family: var(--primary-font-family); }

input {
  font-size: 18px;
  line-height: 21px;
  color: $dark-grey;
}

hr {
  border-top: 1px solid var(--light-grey);
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--primary-font-family);
  // font-weight:  500;
  font-style:   normal;
  font-stretch: normal;
  color: var(--dark-grey);
}

p, span, a, li, input, textarea {
  font-family: var(--primary-font-family);
  font-weight:  inherit;
  font-style:   normal;
  font-stretch: normal;
  color: inherit;
  font-size: 1.125rem;

  &.info {
    // color: var(--light-grey);
    font-weight: 500;
    font-size: 1rem;

    > span {
      font-weight: 500;
      font-size: 1rem;
    }
  }
  
  &.small-info {
    font-size: .75em;
    color: var(--light-grey);
  }
  
  &.small-info-black {
    font-size: .75em;
    color: var(--black);
  }
}

app-cms-module-wrapper {
  p, span, a, li {
    font-size: inherit;
  }
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.uppercase {
  text-transform: uppercase;
}

.image-16-9 {
  padding-top: 56.25%;
}

.bee-header-wrapper,
.bee-button {
  &:not(.secondary) {
    p, span, a {
      color: white;
    }
  }
}

.bold {
  font-weight: bold;
}

.medium {
  font-weight: 500;
}

.italic {
  font-style: italic;
}

.width-100 {
  width: 100%;
}

container-cms-view {
  width: 100%;
}

container-cms-view,
simple-snack-bar {
  h1, h2, h3, h4, h5, h6, p, span, li {
    color: inherit;
  }
}

.cms-module-page-menu-wrapper {
  h1, h2, h3, h4, h5, h6, p, span, a, li {
    color: var(--black);
  }
}

a {
  color: var(--link-color);
  text-decoration: none;
  font-size: 1.125rem;

  &:hover {
    text-decoration: underline;
    color: var(--link-color-hover)
  }
}

app-cms-editor {
  background-color: white;
  display: block;
  width: 100%;
}

app-cms-page,
app-frontend,
app-cms-editor {
  width: 100%;
  
  h1, h1 > span {
    font-size: 3.75rem;
    line-height: 4.25rem;
    margin: 0;
    color: var(--black);
  }
  
  h2, h2 > span {
    font-size: 2.8125rem;
    line-height: 2.8125rem;
    margin: 0;
    color: var(--black);
  }
  
  h3, h3 > span {
    font-size: 2rem;
    line-height: 2rem;
    color: var(--black);
    margin: 0;
  }
  
  h4, h4 > span {
    font-size: 1.5rem;
    line-height: 1.5rem;
    color: var(--black);
    margin: 0;
  }
  
  h5 {
    font-size: 1.375rem;
    line-height: 1.375rem;
    color: var(--black);
    margin: 0;
  }

  h6 {
    font-size: 1.25rem;
    line-height: 1.5625rem;
    font-weight: bold;
    color: var(--black);
    margin: 0;
  }

  p, p > span {
    font-size: 1.125rem;
    line-height: 1.6875rem;
    color: var(--black);
    font-weight: 500;
  }

  .preamble, preamble, bee-premble, bee-premble > span {
    font-size: 1.4375rem;
    line-height: 2.125rem;
    font-weight: 600;

    @media all and (max-width: $mobile-breakpoint) {
      font-size: 1.0625rem;
      line-height: 1.375rem;
    }
  }

  app-cms-module-text {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: inherit;
    }
  }
}

.s4 {
  font-size: 12px;
  line-height: 16px;
  color: #757575;
}

#search-results-list {
  .description {
    span {
      font-size: inherit;
      line-height: inherit;
      color: inherit;
      font-weight: inherit;
    }
  }
}

@media all and (max-width: $mobile-breakpoint) {
  app-cms-page,
  app-frontend,
  app-cms-editor {
    h1 {
      font-size: 2.125rem;
      line-height: 2.375rem;
      margin: 0;
      color: var(--black);
    }
    
    h2 {
      font-size: 1.75rem;
      line-height: 1.75rem;
      margin: 0;
      color: var(--black);
    }

    h3 {
      font-size: 1.375rem;
      line-height: 1.375rem;
      color: var(--black);
      margin: 0;
    }
    
    h4 {
      font-size: 1.3125rem;
      line-height: 1.3125rem;
      color: var(--black);
      margin: 0;
    }

    h5 {
      font-size: 1.1875rem;
      line-height: 1.1875rem;
      color: var(--black);
      margin: 0;
    }

    h6 {
      font-size: 1.125rem;
      line-height: 1.125rem;
      color: var(--black);
      margin: 0;
    }

    p {
      font-size: 1rem;
      line-height: 1.375rem;
    }

    span {
      line-height: inherit;
    }
  }   
}

.date {
  font-size: .9375rem;
  color: var(--primary-text-info-color);
  text-transform: uppercase;
  font-weight: 600;
}

.app-wrapper {
  width: 100%;
  height: auto;
  position: relative;

  app-cms-page {
    display: grid;
    grid-template-rows: auto 1fr auto;
    height: 100%;
    width: 100%;
  }
}

.center {
  text-align: center;
  justify-content: center;
  align-items: center;
}

.align-left {
  justify-content: flex-start;
}

.margin {
  margin: 15px;

  &.sm {
    margin: 5px;
  }

  &.lg {
    margin: 30px;
  }
}

.margin-top {
  margin-top: 15px !important;

  &.sm {
    margin-top: 5px !important;
  }

  &.lg {
    margin-top: 30px !important;
  }

  &.xlg {
    margin-top: 50px !important;
  }
}

.margin-bottom {
  margin-bottom: 15px !important;

  &.sm {
    margin-bottom: 5px !important;
  }

  &.lg {
    margin-bottom: 30px !important;
  }

  &.xlg {
    margin-bottom: 50px !important;
  }
}

.margin-left {
  margin-left: 15px;

  &.sm {
    margin-left: 5px;
  }

  &.lg {
    margin-left: 30px;
  }
}

.margin-right {
  margin-right: 15px;

  &.sm {
    margin-right: 5px;
  }

  &.lg {
    margin-right: 30px;
  }

  &.x-lg {
    margin-right: 35px;
  }
}

@media all and (max-width: $mobile-breakpoint) {
  .mobile-margin-top {
    margin-top: 1rem;

    &.lg {
      margin-top: 2rem;
    }
  }

  .mobile-margin-bottom {
    margin-bottom: 1rem;

    &.lg {
      margin-bottom: 2rem;
    }
  }
}

.mobile-medium {
  @media all and (max-width: $mobile-breakpoint) {
    font-size: 20px;
  }
}

.bee-select-button {
  cursor: pointer;
  border: 2px solid var(--primary-color-hover);
  box-sizing: border-box;

  padding: 5px 30px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;

  color: var(--primary-color-hover);
  margin-left: .5rem;
  margin-bottom: .5rem;

  &.lg {
    padding: 0 35px;
    height: 65px;
    font-size: 16px;
  }

  &.small {
    margin-left: 0;
    height: 44px;
    padding: 0 1.2rem;
    flex-direction: row;
  }

  p, span {
    padding: 0;
    margin: 0;
  }

  .description {
    display: block;
    font-size: 14px;
    color: var(--primary-text-info-color);
  }

  &.selected {
    background-color: var(--primary-color-hover);
    color: var(--white);

    .description {
      color: var(--white);
    }
  }

  &:not(.selected):hover {
    background-color: #EEEEEE;
  }

  &.filled {
    background-color: var(--primary-color);
    border-color: transparent;
    color: var(--primary-text-color);

    &:hover {
      background-color: var(--primary-color-hover);
    }

    &.selected {
      background-color: var(--black);
    }
  }

  &.secondary {
    background-color: transparent;
    border: 1px solid #979797;
    box-sizing: border-box;
    color: #646464;

    &:hover,
    &.active,
    &.selected {
      background-color: #5A5A5A;
      color: var(--white);
    }
  }
}

.bee-button {
  background-color: var(--primary-color);
  font-family: var(--primary-font-family);
  padding: 0 35px;
  height: 65px;
  font-size: 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
  border: none;
  font-weight: bold;

  text-decoration: none;
  cursor: pointer;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  &.active {
    background-color: var(--primary-color-hover);
  }

  &:hover {
    background-color: var(--primary-color-hover);
  }

  &.secondary {
    background-color: transparent;
    border: 2px solid var(--secondary-color);
    box-sizing: border-box;
    color: var(--secondary-text-color);

    &:hover,
    &.active {
      background-color: var(--secondary-color-hover);
    }
  }

  &.small {
    height: 44px;
  }

  &.disabled,
  &[disabled] {
    background-color: #CECECE !important;
    cursor: no-drop;
  }

  &.wide {
    width: 600px;

    max-width: 90%;

    
    @media all and (max-width: $mobile-breakpoint) {
      width: 100%;
      max-width: 100%;
    }
  }

  &.green {
    background-color: var(--green);

    &:hover {
      background-color: var(--green-hover);
    }
  }
}

.drag {
  cursor: move !important;
}

.padding {
  padding: 15px;
}

.material-icons,
bee-animated-icon {
  cursor: pointer; 
}

.mat-form-field {
  width: 100%;
}

.ck-editor__main {

  .ck-content {
    min-height: 100px;
  }
}

mat-grid-tile {
  
  figure {
    flex-direction: column;
  }

  .mat-card {
    width: 75%;
    height: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: transparent;
    color: #000000;
  }
}


.editor-settings {
  .settings-wrapper {
    width: 100%;
    
    input {
      width: 95%; 
      padding: 7px;
    }
  }
}


.small-hr {
  width: 75px;
  height: 3px;
  background-color: #000;
}

.module-instance {
  box-sizing: border-box;
  display: flex;
  position: relative;
  box-sizing: border-box;
  pointer-events: auto;

  &.overflow_hidden {
    overflow: hidden;
  }

  app-cms-module-text {
    p {
      margin: 0;
    }
  }

  > video {
    display: none;
  }

  &.videoBackground {
    position: relative;
    overflow: hidden;

    > video {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      z-index: 0;
      opacity: 0;

      transition: opacity .75s;
    }

    > app-cms-module-wrapper {
      z-index: 2;
    }
  }
}

@media all and (min-width: $mobile-breakpoint) {
  .hide-desktop {
    display: none !important;
  }
}

@media all and (max-width: $mobile-breakpoint) {
  .hide-mobile {
    display: none !important;
  }
}

.admin-wrapper {
  [routerLink],
  [ng-reflect-router-link] {
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }
}


.mat-dialog-container {
  .actions {
    display: flex;
    justify-content: space-between; 
  }
}

.top-actions {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  // box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;
  // border-radius: 4px;

  div {
    display: flex;
    align-items: center;

    & > button {
      margin-left: 15px;
    }
  }
}

.form-group {
  display: flex;
  justify-content: space-between;
}

.icon-125x {
  transform: scale(1.25);
}

.icon-15x {
  transform: scale(1.5);
}

.icon-2x {
  transform: scale(2); 
}

.icon-3x {
  transform: scale(3);
}

.hidden {
  display: none !important;
}

.mat-progress-spinner circle {
  stroke: var(--primary-color) !important;
}

.slim-dialog {
  mat-dialog-container {
    padding: 0;

    @media all and (max-width: $mobile-breakpoint) {
      margin-top: 60px;
    }
  }
}


/* OWL CAROUSEL*/
.owl-stage-outer {
  z-index: -1 !important;
}

.cms-module-slider-wrapper {
  .owl-dots {
    position: absolute;
    width: 100%;
    bottom: 7%; 
  }
}

@media all and (max-width: $mobile-breakpoint) {
  .cms-module-slider-wrapper {
    .owl-dots {
      position: absolute;
      width: 100%;
      bottom: 20px; 
    }
  }
}

.owl-nav {
  position: absolute;
  bottom: 50%;
  width: calc(100% - 80px);
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
}

.owl-carousel .owl-prev {
  background: none !important;
  color: transparent !important;
  position: relative;

  &:hover:before {
    filter: invert(15%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
  }

  &:before {
    content: '';
    background: url(./assets/images/left_arrow.svg) no-repeat scroll 0 0 !important;
    width: 100%;
    height: 100%;
    position: absolute;
    margin-left: -13px; 
  }
}
 
.owl-carousel .owl-next {
  background: none !important;
  color: transparent !important;
  position: relative;

  &:hover:after {
    filter: invert(15%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
  }

  &:after {
    content: '';
    background: url(./assets/images/right_arrow.svg) no-repeat scroll 0 0px !important;
    width: 100%;
    height: 100%;
    position: absolute; 
  }
}

app-cms-module-blog-list .owl-theme .owl-dots .owl-dot span {
  width: 6px;
  height: 6px; 
}

.hr {
  width: 100%;
  height: 2px;
  background-color: var(--light-grey-bg);

  &.white {
    background-color: var(--white);
  }
}

.outer-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;

  &.center {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
}

.inner-wrapper {
  width: 100%;
  max-width: var(--content-width);

  &.center {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
}


/* LOADIGN */
.bee-loading-wrapper {
  min-width: 200px;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* SNACKBAR */
.mat-snack-bar-container {
  background-color: var(--primary-color);
  color: var(--white);
}

app-cms-module-forms .mat-horizontal-content-container {
  overflow: visible !important;
}

.unit-logo {
  max-width: 100%;
}

.round-img-wrapper {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  overflow: hidden;

  background-color: var(--light-grey-bg);

  img {
    width: 100%;
  }
}

.edit-container {
  display: flex;
  flex-wrap: wrap;

  .edit-25 {
    width: 25%;
    padding: 15px;
    box-sizing: border-box;

    &.center {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .edit-33 {
    width: 33.3%;
    padding: 15px;
    box-sizing: border-box;

    &.center {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .edit-50 {
    width: 50%;
    padding: 15px;
    box-sizing: border-box;

    &.center {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .edit-66 {
    width: 66%;
    padding: 15px;
    box-sizing: border-box;

    &.center {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .edit-100 {
    width: 100%;
    padding: 15px;
    box-sizing: border-box;

    &.center {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
  }
}


.tooltip {
  background-color: var(--white) !important;
  color: var(--primary-text-color) !important;

  &:after {
    border-color: var(--white) transparent transparent !important;
  }

  .tooltip-img-wrapper {
    width: 200px; 
    padding-top: 56.25%; 
    position: relative; 
    overflow: hidden;

    img {
      position: absolute; 
      top: 0; 
      left: 0; 
      width: 200px;
    }
  }
}

.bee-dialog {
  max-width: 90vw !important;
}


.flex {
  display: flex;

  &.column {
    flex-direction: column;
  }

  &.row {
    flex-direction: row;
  }

  &.justify-center {
    justify-content: center;
  }

  &.align-center {
    align-items: center;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.end {
    justify-content: flex-end;
    align-items: flex-end;
  }

  &.wrap {
    flex-wrap: wrap;
  }
}

.bee-dialog {
  font-family: var(--primary-font-family);

  &.light-grey {
    mat-dialog-container {
      background-color: var(--light-grey-bg);
    }
  }

  &.large-padding {
    mat-dialog-container {
      padding: 4rem 5.5rem;

      @media all and (max-width: $mobile-breakpoint) {
        padding: 1.5rem 2rem;
      }
    }
  }
}


.form-alert {
  > p {
    color: red;
  }
}

.circle {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #001e24;
  border-radius: 50%;
}

.slide-from-right {
  position: fixed;
  right: -550px;
  border: 2px solid #001e24;
  background-color: #e8eded;
  z-index: 2;
  min-height: 100px;
  width: 550px;
  transition: all linear .15s;
  top: 126px;
  box-sizing: border-box;

  max-width: 100%;
  max-height: calc(100vh - 126px);
  overflow: auto;

  &.show {
    right: 0;

    -webkit-box-shadow: 0px 3px 20px 0px rgba(0,0,0,0.4); 
    box-shadow: 0px 3px 20px 0px rgba(0,0,0,0.4);
  }

  .header {
    background-color: #123339;
    padding: .5rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--primary-text-color);

    span {
      color: var(--primary-text-color);
      font-weight: bold;
      font-size: 1rem;
    }
  }

  .footer {
    padding: .5em;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .notifications-wrapper {

    > div {
      &:hover {
        background-color: #CBD5D5;
      }
    }

    .notification {
      border-radius: 0;

      border-bottom: 1px solid  var(--dark-grey);
      padding: 1em;
      min-height: 100px;

      @media all and (max-width: $mobile-breakpoint) {
        min-height: 80px;
      }

      &:hover {
        background-color: #CBD5D5;
      }
      
      &:not(:last-child) {
        margin-bottom: 0;
      }

      &:first-child {
        background-color: var(--white);

        &:hover {
          background-color: #DAE3E3;
        }
      }

      > p {
        font-weight: bold;
      }
    }
  }
}

// ROUNDED
body.rounded {

  .tag-list-image-wrapper {
    border-radius: 8px;
    overflow: hidden;
  }

  .bee-media-list-wrapper .media-list .media-list-item .media-list-image:hover:after,
  .media-list .media-list-item .media-list-image:hover:after,
  .bee-media-view-wrapper .mediafile-related-wrapper .mediafile-related-items .mediafile-related-item .related-image-wrapper:hover:after {
    border-radius: 8px;
  }

  .media-file-wrapper-audio {
    img {
      border-radius: 8px;

      @media all and (max-width: $mobile-breakpoint) {
        border-radius: 0;
      }
    }
  }

  .media-list-header {
    background-color: #E0F8FF;
  }

  .media-type-indicator {
    border-radius: 6px;
  }

  .media-file-wrapper {
    border-radius: 8px;
    overflow: hidden;

    @media all and (max-width: $mobile-breakpoint) {
      border-radius: 0;
    }
  }

  bee-landscape-image {
    > div {
      border-radius: 8px;
    }
  }

  input {
    border-radius: 30px;

    &:focus-visible {
      outline: none;
    }
  }

  .page-menu-item,
  .video-list-image {
    border-radius: 8px;
  }

  .bee-button,
  button {
    border-radius: 25px;
  }

  .tag-cloud-button {
    border-radius: 8px !important;
  }

  .bee-share-menu,
  .beebase-content owl-carousel-o .blog-list-item .blog-image {
    border-radius: 8px;

    .bee-share-menu-item {
      &:first-child {
        border-radius: 8px 8px 0 0;
      }

      &:last-child {
        border-radius: 0 0 8px 8px;
      }
    }
  }


  .blog-list-item {
    border-radius: 8px;

    .blog-info {
      border-radius: 8px;
    }
  }

  .media-list .media-list-item .media-list-image img,
  .bee-media-view-wrapper .mediafile-related-wrapper .mediafile-related-items .mediafile-related-item .related-image-wrapper img,
  .bee-media-view-wrapper .media-file-wrapper .play-overlay:before,
  formly-field input,
  formly-field textarea,
  formly-field .mat-form-field-flex {
    border-radius: 8px;
  }

  .bee-media-view-wrapper .media-file-wrapper .play-overlay:before {
    @media all and (max-width: $mobile-breakpoint) {
      border-radius: 0;
    }
  }

  app-cms-module-forms .form-outer-wrapper .form-header {
    border-radius: 8px 8px 0 0;
  }

  app-cms-module-forms .form-outer-wrapper .form-wrapper {
    border-radius: 0 0 8px 8px;
  }
}

.inner-content {
  width: 100%;
  max-width: 800px;

  &.lg {
    width: 1080px;
    max-width: 100%;
  }

  &.center {
    display: flex;
    justify-content: center;
  }

  &.left {
    justify-content: flex-start;
    text-align: left;
  }

  h1 {
    margin-top: 7rem;
  }

  @media all and (max-width: $mobile-breakpoint) {
    &.content-padding {
      padding-left: var(--default-padding);
      padding-right: var(--default-padding);

      box-sizing: border-box;
    }
  }
}

.white-background {
  background-color: var(--white);
}

.icon-round-wrapper {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 3px solid var(--black);
  display: flex;
  justify-content: center;
  align-items: center;

  mat-icon {
    font-size: 2rem;
    width: 2rem;
    height: 2rem;
  }
}

.module-divider {
  width: 100%;
  max-width: 1200px;
  height: 1px;
  background-color: #9FB1B4;
}

.content-grey-wrapper {
  padding: 9rem;
  background-color: #E8EDED;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  text-align: left;
  flex-direction: column;
  align-items: center;

  @media all and (max-width: $mobile-breakpoint) {
    padding: 1.5rem;
    padding-top: 4.375rem;
    padding-bottom: 6.875rem;
  }
}

.arrow-before {
  a { 
    &:before {
      font-family: 'Material Icons';
      content: 'chevron_right';
      margin-right: .3rem;
      font-size: 20px;
    }
  }

  app-cms-module-button a:before {
    margin-left: -7px;
  }
}

mat-form-field {
  .mat-input-element {
    font-size: 1.125rem;
    font-family: var(--primary-font-family);
    font-weight: 500;
  }
  
  .mat-form-field-label {
    font-family: var(--primary-font-family);
  }
}

a {
  cursor: pointer;
}

.font-medium {
  font-weight: 500;
}

.divider {
  background-color: var(--grey-line);
  height: 3px;

  &.sm {
    height: 1px;
  }
}

@import "admin.scss";

.max-width-500 {
  max-width: 500px;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


formly-field {
  display: block;
  width: 100%;
}

.slider-wrapper.min-max {
  .mat-slider-horizontal .mat-slider-track-background,
  .mat-slider-horizontal .mat-slider-track-fill,
  .mat-slider-horizontal .mat-slider-track-wrapper,
  .mat-slider-horizontal .mat-slider-wrapper {
    height: 15px;
  }

  .mat-slider-thumb {
    height: 60px;
    width: 60px;
    bottom: -30px;
    right: -30px;
  }
}

.a4-ratio {
  aspect-ratio: 1/1.29;
}

.image-169 {
  aspect-ratio: 16/9;
}

.bee-fab {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 1000;

  background-color: var(--primary-color);
  color: var(--white);

  width: 3rem;
  height: 3rem;

  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  &.left {
    right: auto;
    left: 30px;
  }

  &.top {
    bottom: auto;
    top: 30px;
  }

  &.top-left {
    bottom: auto;
    top: 30px;
    right: auto;
    left: 30px;
  }

  &:hover {
    background-color: var(--primary-color-hover);
  }
}


ngx-mat-datepicker-content {
  background-color: white;
  border-radius: 5px;
  filter: drop-shadow(2px 4px 6px black);
}

.mat-calendar-body-selected {
  background-color: var(--primary-color);
  color: var(--primary-text-color);
}