.admin-wrapper {
  // h1, h1 > span {
  //   font-size: 3.75rem;
  //   line-height: 4.25rem;
  //   margin: 0;
  //   color: var(--black);
  // }
  
  h2, h2 > span {
    font-size: 2.8125rem;
    line-height: 2.8125rem;
    margin: 0;
    color: var(--black);
  }
  
  h3, h3 > span {
    font-size: 2rem;
    line-height: 2rem;
    color: var(--black);
    margin: 0;
  }
  
  h4, h4 > span {
    font-size: 1.5rem;
    line-height: 1.5rem;
    color: var(--black);
    margin: 0;
  }
  
  h5 {
    font-size: 1.375rem;
    line-height: 1.375rem;
    color: var(--black);
    margin: 0;
  }

  h6 {
    font-size: 1.25rem;
    line-height: 1.5625rem;
    font-weight: bold;
    color: var(--black);
    margin: 0;
  }

  .admin-header {
    padding: 1rem;

    // h1 {
    //   font-size: 40px;
    // }
  }

  mat-card {
    overflow: hidden;
    background-color: var(--white);
  }

  td.mat-mdc-cell {
    border-bottom-color: rgba(0,0,0,.12) !important;
    border-bottom-width: 1px !important;
    border-bottom-style: solid !important;
  }

  .grey-card {
    background-color: #D6DDDD;
    border: 2px solid #A1AFAF;
    border-radius: .6rem;
  }

  .bee-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: var(--primary-color);
    color: white;

    h3 {
      color: white;
      font-size: 1.15rem;
      padding: 0;
      text-transform: uppercase;
    }

    > * {
      flex: 1;
    }

    .actions {
      display: flex;
      justify-content: flex-end;

      mat-icon {
        font-size: 36px;
        height: 36px;
        width: 36px;
      }
    }
  }

  .list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    min-height: 75px;

    h4 {
      font-size: 1.125rem;
    }

    > * {
      flex: 1;
      width: 100%;
    }

    &:nth-child(even) {
      background-color: #CECECE25;
    }

    & > h4 {
      // width: 30%;
      font-weight: normal;
    }

    & > div.actions {
      // width: 10%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        color: black;
        text-decoration: none;
      }

      mat-icon {
        margin-left: 10px;
      }
    }
  }

  .bee-progress {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid var(--dark-grey);
    height: 28px;

    > div {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background-color: var(--primary-color);
    }

    > span {
      z-index: 1;
      font-weight: 500;

      &:after {
        content: '%';
      }
    }
  }

  .dashboard-large-number {
    font-size: 4em;
  }

  .bee-flex {
    display: flex;

    &.bee-flex-space-between {
      justify-content: space-between;
    }

    &.bee-flex-align-center {
      align-items: center;
    }
  }

  .no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }
  
  
  .admin-list-wrapper {
    .no-data {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
    }
  
    .list-header {
      margin: 15px;
      display: flex;
      justify-content: space-between;
  
      & > h3 {
        width: 30%;
      }
  
      & > div.actions {
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  
    mat-expansion-panel.list-item {
      flex-direction: column;
    }
  }
  
  .list-image,
  .round-image {
    width: 3em;
    height: 3em;
    background-position: center;
    background-size: cover;
    border-radius: 1em;

    background-color: var(--light-grey-bg);

    &.sm {
      width: 2em;
      height: 2em;
      border-radius: .67em;
    }
  }
}


.phone-emulator {
  width: 300px;
  max-width: 300px;
  position: relative;
  background-image: url('assets/images/mobile_phone.png');
  background-position: top;
  background-size: contain;

  height: 582.75px;
  background-repeat: no-repeat;
}

.admin-input-info-wrapper {
  display: grid;
  grid-template-columns: 1fr 40px;

  bee-info {
    grid-column-start: 2;
    height: 30px;
    margin-left: 10px;
  }

}

.mat-tooltip {
  font-size: 1rem !important;
}

.disabled {
  opacity: .5 !important;
  pointer-events: none !important;
}

.hover:hover {
  cursor: pointer;
  background-color: var(--light-grey-bg);
}