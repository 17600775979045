$max-width: 100%;
$max-width-module: 1080px;


$pad-breakpoint: 1023px;
$pad-landscape-breakpoint: 1080px;

$mobile-breakpoint: 767px;
$mobile-wrapper-padding: 20px;


/* FONT SIZES */
$font-size-20: 1.25rem;
$font-size-24: 1.5rem;
$font-size-32: 2rem;
$font-size-36: 2.25rem;



$mobile-padding: 1rem;